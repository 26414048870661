<template>
  <div>
    <Header back="返回" title="合作客户" index="首页" titleOne="客户管理" titleTwo="合作客户" beforeTitle="详情" />
    <div class="content">
      <h1>客户信息</h1>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <div class="text_one" style="margin-top:20px">
            <ul>
              <div class="text_l">
                <li>
                  <p>企业名称</p>
                  <span>{{detailList.company_name}}</span>
                </li>
                <li>
                  <p>企业简称</p>
                  <span>{{detailList.short_name}}</span>
                </li>
                <li>
                  <p>组织机构代码</p>
                  <span>{{detailList.org_code}}</span>
                </li>
                <li>
                  <p>信用代码</p>
                  <span>{{detailList.code}}</span>
                </li>
                <li>
                  <p>注册资本（万元）</p>
                  <span>{{detailList.register_capital}}</span>
                </li>
                <li>
                  <p>注册时间</p>
                  <span>{{detailList.register_time}}</span>
                </li>
                <li>
                  <p>是否高企</p>
                  <span
                    v-if="detailList.is_high && detailList.reassessment_time"
                  >{{detailList.reassessment_time}}</span>
                  <span v-if="!detailList.is_high">否</span>
                </li>
                <li>
                  <p>所属区域</p>
                  <span>{{detailList.area}}</span>
                </li>
                <li>
                  <p>企业类型</p>
                  <span v-if="detailList.com_type == 1">有限责任公司</span>
                  <span v-if="detailList.com_type == 2">股份有限公司</span>
                  <span v-if="detailList.com_type == 3">国企</span>
                  <span v-if="detailList.com_type == 4">外商投资企业</span>
                  <span v-if="detailList.com_type == 5">独资企业</span>
                  <span v-if="detailList.com_type == 6">个体工商户</span>
                  <span v-if="detailList.com_type == 7">联营企业</span>
                  <span v-if="detailList.com_type == 8">集体所有制</span>
                  <span v-if="detailList.com_type == 9">有限合伙</span>
                  <span v-if="detailList.com_type == 10">普通合伙</span>
                </li>
                <li>
                  <p>经营状态</p>
                  <span v-if="detailList.status == 1">在业</span>
                  <span v-if="detailList.status == 2">存续</span>
                  <span v-if="detailList.status == 3">正常</span>
                  <span v-if="detailList.status == 4">注销</span>
                  <span v-if="detailList.status == 5">吊销</span>
                </li>
                <li>
                  <p>所属行业</p>
                  <span>{{detailList.industry_one_name}}</span>
                </li>
                <li>
                  <p>资产规模</p>
                  <span>{{detailList.asset_scale}}</span>
                </li>
                <li>
                  <p>公司销售额（万元）</p>
                  <span>{{detailList.sales_scale}}</span>
                </li>
                <li>
                  <p>研发投入（万元）</p>
                  <span>{{detailList.yftr}}</span>
                </li>
              </div>
              <div class="text_r">
                <li>
                  <p>法人代表</p>
                  <span>{{detailList.legal_person}}</span>
                </li>
                <li>
                  <p>客户联系人</p>
                  <span>{{detailList.business_manager}}</span>
                </li>
                <li>
                  <p>客户联系手机</p>
                  <span>{{detailList.tel}}</span>
                </li>
                <li>
                  <p>客服管家</p>
                  <span>{{detailList.project_specialist}}</span>
                </li>
                <li>
                  <p>企业联系人</p>
                  <span>{{detailList.contact}}</span>
                </li>
                <li>
                  <p>企业固定电话</p>
                  <span>{{detailList.mobile}}</span>
                </li>
                <li>
                  <p>企业邮箱</p>
                  <span>{{detailList.email}}</span>
                </li>
                <li>
                  <p>企业网址</p>
                  <span>{{detailList.domain}}</span>
                </li>
                <li>
                  <p>专利数量</p>
                  <span>{{detailList.patent_count}}</span>
                </li>
                <li>
                  <p>软著数量</p>
                  <span>{{detailList.soft_count}}</span>
                </li>
                <li>
                  <p>商标数量</p>
                  <span>{{detailList.brand_count}}</span>
                </li>
                <li>
                  <p>登记机关</p>
                  <span>{{detailList.register_org}}</span>
                </li>
                <li>
                  <p>注册地址</p>
                  <span>{{detailList.register_address}}</span>
                </li>
                <li>
                  <p>公司地址</p>
                  <span>{{detailList.address}}</span>
                </li>
              </div>
            </ul>
            <li class="text_li">
              <p>经营范围</p>
              <span>{{detailList.jyfw}}</span>
            </li>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员花名册" name="roster" style="margin-top:24px">
          <div class="topUsers">
            <div style="display:flex;width:90%" class="top_header">
              <div>
                <el-select
                  clearable
                  v-model="rosterParams.education"
                  placeholder="请选择学历类型"
                  popper-class="select_box"
                >
                  <el-option label="博士" value="1"></el-option>
                  <el-option label="硕士" value="2"></el-option>
                  <el-option label="本科" value="3"></el-option>
                  <el-option label="专科及以下" value="4"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.type" placeholder="请选择人员类型">
                  <el-option label="在职人员" value="1"></el-option>
                  <el-option label="兼职人员" value="2"></el-option>
                  <el-option label="临时聘用人员" value="3"></el-option>
                  <el-option label="外籍人员" value="4"></el-option>
                  <el-option label="留学归国人员" value="5"></el-option>
                  <el-option label="千人计划人员" value="6"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.is_science" placeholder="请选择是否科技人员">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </div>
              <div>
                <el-select clearable v-model="rosterParams.insured" placeholder="请选择是否参保">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </div>
              <el-input
                clearable
                class="import"
                v-model="rosterParams.keywords"
                placeholder="请输入人员姓名"
                @keyup.enter.native="handleSearch()"
              ></el-input>
              <el-button class="search" @click="handleSearch()">查询</el-button>
            </div>
          </div>
          <el-table :data="tableDataMan.data" style="width: 100%">
            <el-table-column prop="name" label="姓名" width="130"></el-table-column>
            <el-table-column prop="identity_code" label="身份证号" width="180"></el-table-column>
            <el-table-column prop="birthday" show-overflow-tooltip label="出生年月">
              <template slot-scope="scope">{{scope.row.birthday?scope.row.birthday:'-'}}</template>
            </el-table-column>
            <el-table-column prop="tel" label="手机号">
              <template slot-scope="scope">{{scope.row.tel?scope.row.tel:'-'}}</template>
            </el-table-column>
            <el-table-column prop="entry_time" show-overflow-tooltip label="入职时间">
              <template slot-scope="scope">{{scope.row.entry_time?scope.row.entry_time:'-'}}</template>
            </el-table-column>
            <el-table-column prop="quit_time" show-overflow-tooltip label="离职时间">
              <template slot-scope="scope">{{scope.row.quit_time?scope.row.quit_time:'-'}}</template>
            </el-table-column>
            <el-table-column prop="title" label="职称" width="100px">
              <template slot-scope="scope">
                <span v-if="scope.row.title == 1">高级</span>
                <span v-if="scope.row.title == 2">中级</span>
                <span v-if="scope.row.title == 3">初级</span>
                <span v-if="scope.row.title == 4">高级技工</span>
              </template>
            </el-table-column>

            <el-table-column prop="education" label="学历" width="100px">
              <template slot-scope="scope">
                <span v-if="scope.row.education == 1">博士</span>
                <span v-if="scope.row.education == 2">硕士</span>
                <span v-if="scope.row.education == 3">本科</span>
                <span v-if="scope.row.education == 4">大专及以下</span>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="人员类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1">在职</span>
                <span v-if="scope.row.type == 2">兼职</span>
                <span v-if="scope.row.type == 3">临时</span>
                <span v-if="scope.row.type == 4">外籍</span>
                <span v-if="scope.row.type == 5">留学归国</span>
                <span v-if="scope.row.type == 6">千人计划</span>
              </template>
            </el-table-column>
            <el-table-column prop="is_science" label="是否科技人员" width="110px">
              <template slot-scope="scope">
                <span v-if="scope.row.is_science == 1">是</span>
                <span v-if="scope.row.is_science == 0">否</span>
              </template>
            </el-table-column>
            <el-table-column prop="insured" label="是否参保" width="90px">
              <template slot-scope="scope">
                <span v-if="scope.row.insured == 1">是</span>
                <span v-if="scope.row.insured == 0">否</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="rosterParams.page"
              :page-sizes="[2, 10, 20, 40]"
              :page-size="rosterParams.limit"
              layout="sizes, prev, pager, next,total,jumper"
              :total="tableDataMan.total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="人员资源" name="second">
          <h2>总体情况</h2>
          <div class="ul_box">
            <ul>
              <li>
                <span>人员类型</span>
                <span>企业职工（人）</span>
                <span>科技人员（人）</span>
              </li>
              <li>
                <span>总人数</span>
                <span>{{peopleNum}}</span>
                <span>{{humanNum}}</span>
              </li>
              <li>
                <span>其中：在职人员</span>
                <span>{{humanform.q_zz}}</span>
                <span>{{humanform.k_zz}}</span>
              </li>
              <li>
                <span>兼职人员</span>
                <span>{{humanform.q_jz}}</span>
                <span>{{humanform.k_jz}}</span>
              </li>
              <li>
                <span>临时聘用人员</span>
                <span>{{humanform.q_ls}}</span>
                <span>{{humanform.k_ls}}</span>
              </li>
              <li></li>
              <li>
                <span>外籍人员</span>
                <span>{{humanform.q_wj}}</span>
                <span>{{humanform.k_wj}}</span>
              </li>
              <li>
                <span>留学归国人员</span>
                <span>{{humanform.q_lx}}</span>
                <span>{{humanform.k_lx}}</span>
              </li>
              <li>
                <span>千人计划人员</span>
                <span>{{humanform.q_gr}}</span>
                <span>{{humanform.k_gr}}</span>
              </li>
            </ul>
          </div>
          <h2>全体人员结构</h2>
          <div class="ul_box">
            <ul>
              <li>
                <span>结构类型</span>
                <span>人数</span>
              </li>
              <li>
                <span>博士</span>
                <span>{{humanform.x_bx}}</span>
              </li>
              <li>
                <span>硕士</span>
                <span>{{humanform.x_ss}}</span>
              </li>
              <li>
                <span>本科</span>
                <span>{{humanform.x_bk}}</span>
              </li>
              <li>
                <span>大专及以下</span>
                <span>{{humanform.x_dz}}</span>
              </li>
              <li>
                <span>高级职称</span>
                <span>{{humanform.z_gj}}</span>
              </li>
              <li>
                <span>中级职称</span>
                <span>{{humanform.z_zj}}</span>
              </li>
              <li>
                <span>初级职称</span>
                <span>{{humanform.z_cj}}</span>
              </li>
              <li>
                <span>高级技工</span>
                <span>{{humanform.z_jg}}</span>
              </li>
            </ul>
          </div>
          <h2>企业职工人数情况表</h2>
          <div class="ul_box">
            <ul>
              <li>
                <span>职工人数情况类型</span>
                <span>人数</span>
              </li>
              <li>
                <span>全年月平均在职人员①</span>
                <span>{{humanform.qly}}</span>
              </li>
              <li>
                <span>其中，参保人员</span>
                <span>{{humanform.cbrs}}</span>
              </li>
              <li>
                <span>工作时间超过183天的兼职人员②</span>
                <span>{{humanform.jzrs}}</span>
              </li>
              <li>
                <span>工作时间超过183天的临时聘用人员③</span>
                <span>{{humanform.lsrs}}</span>
              </li>
              <li>
                <span>企业职工总数（①+②+③）</span>
                <span>{{humanform.qyzrs}}</span>
              </li>
              <li>
                <span>全年月平均在职人员数与参保人数差异的情况说明</span>
                <span>{{humanform.qksm}}</span>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="联系信息" name="third">
          <h2>企业负责人</h2>
          <div class="telinfo">
            <ul>
              <li>
                <p>姓名</p>
                <span>{{person_qy.contact_name}}</span>
              </li>
              <li>
                <p>联系方式</p>
                <span>{{person_qy.contact_tel}}</span>
              </li>
              <li>
                <p>生日</p>
                <span>{{person_qy.contact_birthday}}</span>
              </li>
              <li>
                <p>邮箱</p>
                <span>{{person_qy.contact_email}}</span>
              </li>
              <li>
                <p>性格爱好特点</p>
                <span>{{person_qy.contact_character}}</span>
              </li>
            </ul>
          </div>
          <h2>企业联系人</h2>
          <div class="telinfo">
            <ul>
              <li>
                <p>姓名</p>
                <span>{{person_tel.contact_name}}</span>
              </li>
              <li>
                <p>联系方式</p>
                <span>{{person_tel.contact_tel}}</span>
              </li>
              <li>
                <p>生日</p>
                <span>{{person_tel.contact_birthday}}</span>
              </li>
              <li>
                <p>邮箱</p>
                <span>{{person_tel.contact_email}}</span>
              </li>
              <li>
                <p>性格爱好特点</p>
                <span>{{person_tel.contact_character}}</span>
              </li>
            </ul>
          </div>
          <h2>项目对接人</h2>
          <div class="telinfo">
            <ul>
              <li>
                <p>姓名</p>
                <span>{{person_xm.contact_name}}</span>
              </li>
              <li>
                <p>联系方式</p>
                <span>{{person_xm.contact_tel}}</span>
              </li>
              <li>
                <p>生日</p>
                <span>{{person_xm.contact_birthday}}</span>
              </li>
              <li>
                <p>邮箱</p>
                <span>{{person_xm.contact_email}}</span>
              </li>
              <li>
                <p>性格爱好特点</p>
                <span>{{person_xm.contact_character}}</span>
              </li>
            </ul>
          </div>
          <h2>财务负责人</h2>
          <div class="telinfo">
            <ul>
              <li>
                <p>姓名</p>
                <span>{{person_cw.contact_name}}</span>
              </li>
              <li>
                <p>联系方式</p>
                <span>{{person_cw.contact_tel}}</span>
              </li>
              <li>
                <p>生日</p>
                <span>{{person_cw.contact_birthday}}</span>
              </li>
              <li>
                <p>邮箱</p>
                <span>{{person_cw.contact_email}}</span>
              </li>
              <li>
                <p>性格爱好特点</p>
                <span>{{person_cw.contact_character}}</span>
              </li>
            </ul>
          </div>
          <h2>技术负责人</h2>
          <div class="telinfo">
            <ul>
              <li>
                <p>姓名</p>
                <span>{{person_js.contact_name}}</span>
              </li>
              <li>
                <p>联系方式</p>
                <span>{{person_js.contact_tel}}</span>
              </li>
              <li>
                <p>生日</p>
                <span>{{person_js.contact_birthday}}</span>
              </li>
              <li>
                <p>邮箱</p>
                <span>{{person_js.contact_email}}</span>
              </li>
              <li>
                <p>性格爱好特点</p>
                <span>{{person_js.contact_character}}</span>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="业务拜访" name="fourth">
          <h2>业务情况</h2>
          <div class="ywlook">
            <ul>
              <li>
                <p>客户联系人</p>
                <span>{{yw_look.custom_manager}}</span>
              </li>
              <li style="width:65%">
                <p style="width:10%">客户级别</p>
                <span>{{yw_look.level}}</span>
              </li>
              <li>
                <p>拜访次数</p>
                <span>{{yw_look.visit_count}}</span>
              </li>
              <li>
                <p>客户来源</p>
                <span v-if="yw_look.custom_source == 1">自主研发</span>
                <span v-if="yw_look.custom_source == 2">转介绍</span>
                <span v-if="yw_look.custom_source == 3">渠道推介</span>
              </li>
              <li>
                <p v-if="yw_look.custom_source == 1">研发方式</p>
                <p v-if="yw_look.custom_source == 2">转介绍人</p>
                <p v-if="yw_look.custom_source == 3">渠道名称</p>
                <span v-if="yw_look.custom_source == 2">{{yw_look.introducer}}</span>
                <span v-if="yw_look.visit_count">{{yw_look.visit_count}}</span>
                <span v-if="yw_look.development_model == 1 && yw_look.custom_source == 1">电话</span>
                <span v-if="yw_look.development_model == 2 && yw_look.custom_source == 1">活动</span>
                <span v-if="yw_look.development_model == 3 && yw_look.custom_source == 1">线上</span>
              </li>
            </ul>
          </div>
          <div class="ywbox" v-for="(item,index) in ywlist" :key="index">
            <h2>{{item.visit_time}}</h2>
            <ul>
              <li>
                <p>地点</p>
                <span>{{item.visit_place}}</span>
              </li>
              <li>
                <p>拜访人员</p>
                <span>{{item.visit_person}}</span>
              </li>
              <li>
                <p>拜访纪要</p>
                <span>{{item.visit_summary}}</span>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户资料" name="info">
          <InfoDetail ref="info" :com_id="$route.query.id" :type="$route.query.type" />
        </el-tab-pane>
        <el-tab-pane label="项目列表" name="list">
          <ListDetail ref="list" :com_id="$route.query.id" :type="$route.query.type" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import InfoDetail from './customInfoTab.vue'
import ListDetail from './partnerListTab.vue'
export default {
  components: {
    InfoDetail,
    ListDetail,
  },
  data () {
    return {
      detailList: {},//基本信息
      humanform: {},//人员资源
      person_qy: {},//联系信息-企业负责人
      person_tel: {},//联系信息-企业联系人
      person_xm: {},//联系信息-项目对接人
      person_cw: {},//联系信息-财务负责人
      person_js: {},//联系信息-技术负责人
      yw_look: {},//业务拜访
      ywlist: [],//业务拜访扩展
      id: this.$route.query.id,
      activeName: "first",
      tableDataMan: [],//人员花名册表格列表存储
      rosterParams: {
        com_id: this.$route.query.id,
        token: localStorage.getItem('token'),
        page: 1,
        limit: 10,
        keywords: '',
        insured: '',//参保
        is_science: '',//科技人员
        type: '',//人员类型
      },
    }
  },
  computed: {
    peopleNum () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.q_zz) || 0;
      let sum2 = parseFloat(this.humanform.q_jz) || 0;
      let sum3 = parseFloat(this.humanform.q_ls) || 0;
      let sum4 = parseFloat(this.humanform.q_wj) || 0;
      let sum5 = parseFloat(this.humanform.q_lx) || 0;
      let sum6 = parseFloat(this.humanform.q_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      return sum || 0;
    },
    humanNum () {
      let sum = 0;
      let sum1 = parseFloat(this.humanform.k_zz) || 0;
      let sum2 = parseFloat(this.humanform.k_jz) || 0;
      let sum3 = parseFloat(this.humanform.k_ls) || 0;
      let sum4 = parseFloat(this.humanform.k_wj) || 0;
      let sum5 = parseFloat(this.humanform.k_lx) || 0;
      let sum6 = parseFloat(this.humanform.k_gr) || 0;
      sum = sum1 + sum2 + sum3 + sum4 + sum5 + sum6;
      return sum || 0;
    }
  },
  created () {
    this.getCompanyInfo()
    // this.getMember()
  },
  methods: {
    // 人员花名册表格数据
    getRosterList () {
      this.axios.get('/api/company/company_person_list', { params: { ...this.rosterParams } }).then((res) => {
        this.tableDataMan = res.data
      })
    },
    handleSearch () {
      this.getRosterList()
    },
    handleSizeChange (val) {
      this.getRosterList(this.rosterParams.limit = val)
    },
    handleCurrentChange (val) {
      this.getRosterList(this.rosterParams.page = val)
    },
    getCompanyInfo () {
      this.axios.get('/api/company/view', { params: { id: this.id } }).then((res) => {
        this.detailList = res.data
      })
    },
    handleClick (tab, event) {
      if (tab.name == 'second') {
        this.axios.get('/api/company/view_relation', { params: { id: this.id, type: "human" } }).then((res) => {
          // console.log(res)
          this.humanform = res.data
        })
      } else if (tab.name == 'third') {
        this.axios.get('/api/company/view_relation', { params: { id: this.id, type: "contact" } }).then((res) => {
          console.log(this.person_qy)
          this.person_qy = res.data.company_manager[0]
          this.person_tel = res.data.company_contact[0]
          this.person_cw = res.data.finance_manager[0]
          this.person_xm = res.data.project_manager[0]
          this.person_js = res.data.tech_manager[0]
        })
      } else if (tab.name == 'fourth') {
        this.axios.get('/api/company/view_relation', { params: { id: this.id, type: "business" } }).then((res) => {
          console.log(res)
          this.yw_look = res.data
          this.ywlist = res.data.visit
        })
      } else if (tab.name == 'roster') {
        this.getRosterList()
      }
      this.$refs[this.activeName].getList()
    },
  }
}
</script>

<style scoped>
.text_one ul li {
  display: flex;
  align-items: center;
}
.text_one ul li p {
  display: block;
  width: 20%;
}
.text_one ul li span {
  color: #909399;
}
.text_l,
.text_r {
  width: 50%;
}
.text_one ul {
  display: flex;
}
.text_li {
  width: 100%;
  display: flex;
  align-items: center;
}
.text_li span {
  color: #909399;
}
.text_li p {
  width: 10%;
}
.ul_box {
  width: 100%;
}
.ul_box li {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f3f8;
}
.ul_box li span {
  display: inline-block;
  width: 25%;
  color: #909399;
}
.ul_box ul li:nth-child(1) span {
  color: #000;
}
.telinfo {
  border-bottom: 1px solid #f2f3f8;
  padding-bottom: 20px;
}
.telinfo ul li {
  height: 48px;
  display: flex;
  align-items: center;
}
.telinfo ul li span {
  color: #909399;
}
.telinfo ul li p {
  display: inline-block;
  width: 10%;
}
.ywlook {
  border-bottom: 1px solid #f2f3f8;
  padding-bottom: 20px;
}
.ywlook ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.ywlook ul li {
  width: 30%;
  display: flex;
  align-items: center;
}
.ywlook ul li p {
  width: 20%;
}
.ywlook ul li span {
  color: #909399;
}
.ywbox {
  border-bottom: 1px solid #f2f3f8;
  padding: 10px 0;
}
.ywbox ul li {
  display: flex;
  align-items: center;
  height: 48px;
}
.ywbox ul li p {
  display: inline-block;
  width: 10%;
}
.ywbox ul li span {
  color: #909399;
}
</style>